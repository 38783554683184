import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import DinerFooter from "../../components/dinerFooter";
import Slider from "react-slick";
import { HomeDescription } from "../../backendUI/Home/config";
import config from "../../config/config";
import WhatsAppButton from "../../components/whatsappButton";
import DeletePasswordForm from "./DeletePasswordForm";


class DeletePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            HeadTitle: "",
        };
    }
    componentDidMount() {
        if (!localStorage.getItem("closePopup")) {
            this.setState({
                displayPopup: true,
            });
        } else {
            this.setState({
                displayPopup: false,
            });
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        if (localStorage.getItem("selectedLocation")) {
            var selectedCity = config.slugify(
                localStorage.getItem("selectedLocation")
            );
            this.setState({
                selectedCity: "/" + selectedCity + "/diner-homepage",
            });
        }

        this.getHeadTitle();
    }

    getHeadTitle = () => {
        HomeDescription().then((res) => {
            if (res.success == true) {
                this.setState({
                    HeadTitle: res.data[0].description,
                });
            }
        });
    };

    render() {

        return (
            <div className="css2" >
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li className="active">
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>

                        </ul>

                        <div className="menu_overlay"></div>
                    </div>
                </header>
                <section className="home_slider diner_home">
                    <div className="container">
                        <h3 className="color_036 top_title font-weight-normal">
                            Welcome to
                        </h3>
                        <div className="logo_wrap">
                            <img
                                src="/assets/images/home/logo.png"
                                alt="Chef Pin"
                                className="img-fluid"
                            />
                        </div>
                        {/* <p>
                            Are you a talented home chef or home baker whose passion is to cook delicious dishes?
                        </p> */}
                        <h1 className="color_036 build_text new">
                            {/* {this.state.HeadTitle != "" &&
                                this.state.HeadTitle.replace(/(<([^>]+)>)/gi, "")} */}
                            Looking for fresh and hygienic food that tastes just like home? Then Chef Pin is your go-to solution! Order on our app and have your delicious homecooked meal delivered to your doorstep in Delhi NCR, Mumbai and Bengaluru. We are India's leading platform for home chefs and home bakers.


                        </h1>
                        <div id="home_slider" className="slider_big_img">
                            <div className="slider_wrapper">
                                <div id="slider" className="slider">
                                    <div className="slides img_text_wrap active">
                                        <div className="img_wrap">
                                            <img
                                                src="/assets/images/home/slider-1.png"
                                                alt="home chef"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                    <div className="slides img_text_wrap">
                                        <div className="img_wrap">
                                            <img
                                                src="/assets/images/home/slider-2.png"
                                                alt="Indian home chef"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                    <div className="slides img_text_wrap">
                                        <div className="img_wrap">
                                            <img
                                                src="/assets/images/home/slider-3.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="slider_nav">
                                    <div className="prev btn_icon">
                                        <img src="/assets/images/icons/white-left-arrow.png" />
                                    </div>
                                    <div className="next btn_icon">
                                        <img src="/assets/images/icons/white-right-arrow.png" />
                                    </div>
                                </div>
                            </div>

                            <ul className="slider_thumb">
                                <li className="active slide-change-btn thumb-img" id="0">
                                    <img
                                        src="/assets/images/home/slider-1.png"
                                        alt="home chef"
                                        className="img-fluid"
                                    />
                                </li>
                                <li className="slide-change-btn thumb-img" id="1">
                                    <img
                                        src="/assets/images/home/slider-2.png"
                                        alt="Indian home chef"
                                        className="img-fluid"
                                    />
                                </li>
                                <li className="slide-change-btn thumb-img" id="2">
                                    <img
                                        src="/assets/images/home/slider-3.png"
                                        alt="homechefs india"
                                        className="img-fluid"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <DeletePasswordForm />

                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>


                <DinerFooter />

            </div>
        );
    }
}

export default DeletePassword;
