/*
#############################################################################
# Name : config.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to add the confirguration for the Chefpin
#############################################################################
*/
let config = {
  /* API Pointing URL starts here*/

  //APIURL: "https://api.techtesting.in/api/",
  APIURL: 'https://api-dev.chefp.in/api/',
  //APIURL: 'https://api.chefp.in/api/',
  siteKey: '6Lffxt8ZAAAAAEEN6KPujzyZXuUJryK6uxA3NNHm',
  secretKey: '6Lffxt8ZAAAAAHVxKVi-JnqkvADzfjk9sJroZyhS',
  placesKey: 'AIzaSyAM9tXQe4ikJvpt4PwbWVR9gVOaYPoWuT8',
  /* API Pointing URL ends here*/

  /* RazorPay Credentails starts here*/
  razorKey: 'rzp_test_BoGaOPskqkL01M', //testing
  //razorKey: 'rzp_live_iWIwHfn5FlVefh', //live
  /* RazorPay Credentails ends here*/

  /* Local Credentais for Google and Facebook Login */

  /* CLIENT_ID : "189863917203-ommseie05lreggmhu45ofqsbkeeu11gu.apps.googleusercontent.com",
                    APP_ID : "1069014190280593", */

  /* Live Credentais for Google and Facebook Login */

  /* CLIENT_ID:
    '161292080521-002jkhk7932fqjhqkbt9dpegi64ef1gi.apps.googleusercontent.com', */
    CLIENT_ID: 	'865071826900-jqsjv8u2rpcv4m2uohkdf6p3tngl2jre.apps.googleusercontent.com',
  APP_ID: '215119167076024',

  /* Slug code for url starts here */
  slugify: (string) => {
    const a =
      'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b =
      'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }
  /* Slug code for url ends here */
};

export default config;
