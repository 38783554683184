import React, { useState } from "react";
import MultiSelectDropdown from "../../components/MultiSelect";
import Select from "react-select";
import config from "../../config/config";
import { NotificationManager } from 'react-notifications';

function DeletePasswordForm() {
    const [formData, setFormData] = useState({
        name: "",
        mobile_number: "",
        email: "",
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(formData);

        var formdata = new FormData();

        formdata.append("name", formData.name);
        formdata.append("mobile_number", formData.mobile_number);
        formdata.append("email", formData.email);


        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            config.APIURL + "v1/account-deletion-enquiry",
            requestOptions
        )
		.then((response) => response.json())
		.then((result) => {
			if (result.status === 200) {
				NotificationManager.success(
				  result.message,
				  'Successful!',
				  5000
				);
				setTimeout(function(){
					window.location.href = "/";
				}, 5000);
				
			}else{
				NotificationManager.error(
				  result.message,
				  'Error!',
				  5000
				);
			}
		})
		.catch((error) => {
			NotificationManager.error(
			  error.message,
			  'Error!',
			  5000
			);
		});
    };
    return (
        <form
            onSubmit={handleSubmit}
            style={{
                maxWidth: "700px",
                margin: "0 auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                backgroundColor: "#f8f8f8",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <h2 className="text-uppercase color_C07 mb-4" style={{ fontSize: "34px" }} >REQUEST FOR ACCOUNT DELETION</h2>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Your Name*:
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
				
				<div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Your Email ID*:
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>
				
                <div style={{ display: "flex", alignItems: "center" }}>
                    <label
                        style={{ width: "150px", textAlign: "right", paddingRight: "10px" }}
                    >
                        Your Phone Number*:
                    </label>
                    <input
                        type="tel"
                        name="mobile_number"
                        value={formData.mobile_number}
                        onChange={handleInputChange}
                        required
                        title="Please enter exactly 10 digits"
                        pattern="[1-9]{1}[0-9]{9}"
                        style={{ padding: "10px", borderRadius: "5px", border: "1px solid #ccc", width: "70%" }}
                    />
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button type="submit" style={{
                        backgroundColor: "#007BFF",
                        color: "#fff",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                    }}>Submit</button>
                </div>
            </div>
        </form>
    );
}

export default DeletePasswordForm;
